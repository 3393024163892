import styled from 'styled-components';
import AppColors from '../../../styles/colors';
import BgCurveUp from '../../../images/bg-curveUp.png';
export const HeaderDiv = styled.div`
  display: block;
  width: 100%;
  height: 275px;
  padding: 150px 0 0 0;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1399px) {
    width: 1200px;
    height: 240px;
    padding-top: 135px;
  }
  @media (max-width: 1199px) {
    width: 940px;
    padding: 120px 0 0 0;
  }
  @media (max-width: 991px) {
    width: 700px;
    height: auto;
    padding-top: 130px;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-top: 110px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Header = styled.h1`
  transition-delay: 5s;
  opacity: 1;
  transform: translateZ(0);
  transition-timing-function: ease;
  transition-duration: 1s;
  transition-property: opacity, transform;
  margin: 0;
  font-family: interstate, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  line-height: 62px;
  text-transform: uppercase;
  color: ${AppColors.White};
  padding: 0;
  @media (max-width: 1199px) {
    font-size: 50px;
    line-height: 52px;
  }
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
  }
`;

export const BottomBlock = styled.div`
  background: url(${BgCurveUp}) no-repeat bottom center;
  position: relative;
  height: 104px;
  background-size: contain;
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1199px) {
    height: 85px;
  }
  @media (max-width: 767px) {
    margin-top: -40px;
  }
`;
