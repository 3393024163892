import React from 'react';
import * as Styled from './pageHeaderStyles';
import AOS from 'aos';

const PageHeader = ( props ) => {
  
  React.useLayoutEffect(() => {
    AOS.init({
      disable: function () {
        const maxWidth = 767;
        return window.innerWidth < maxWidth;
      },
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <Styled.HeaderDiv
        data-aos="fade-up"
        data-aos-delay="800"
        data-aos-duration="1000"
      >
        <Styled.Header>{props.header}</Styled.Header>
      </Styled.HeaderDiv>
      <Styled.BottomBlock />
    </>
  );
};

export default PageHeader;
