import * as React from 'react';
import PageHeader from '../components/dynamic/pageHeader/pageHeader';
import * as Styled from '../styles';
import { graphql } from 'gatsby';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Footer from '../components/footer/footer';
import TopBar from '../components/topBar/topBar';
import NavigationBar from '../components/navigationBar/navigationBar';
import FooterLinks from '../components/footerLinks/footerLinks';
import GlobalStyles from '../styles/globalStyles';
import { Helmet } from 'react-helmet';
import { HeaderH3, ParaText } from '../components/dynamic/richTextWithCenterDiv/richTextWithCenterDivStyles'
import LogoOG from  './../images/og-logo.png';
const NotFoundPage = ({ data, location }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaText>{children}</ParaText>,

      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3>{children}</HeaderH3>
      ),

      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle href={node?.data?.uri}>{children}</Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const pageTitle = data?.allContentfulBurnabyRefinery?.edges[0]?.node?.title;
  const pageData =
    data?.allContentfulBurnabyRefinery?.edges[0]?.node?.sections[0];
  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Page Not Found - Burnaby Refinery</title>
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta property="og:image" content={LogoOG}></meta>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GlobalStyles />
      <TopBar desktop />
      <Styled.Clear />
      <NavigationBar />
      <PageHeader header={pageTitle} />

      <Styled.PageNotFoundInnerContainer>
        <HeaderH3>{pageData?.header}</HeaderH3>
        {data &&
          documentToReactComponents(
            JSON.parse(pageData?.contentDetails?.raw),
            optionsMainStyle
          )}
      </Styled.PageNotFoundInnerContainer>

      <FooterLinks />
      <Footer />
    </>
  );
};

export default NotFoundPage;
export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentfulBurnabyRefinery(
      filter: { type: { type: { eq: "pageNotFound" } } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          contentModels
          sections {
            ... on ContentfulContent {
              id
              title
              type
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
